import React from "react";
import ReactDOM from "react-dom";

const componentMount = (render: props => React.Node) => (element, props) => {
  ReactDOM.render(render(props), element);

  return () => {
    ReactDOM.unmountComponentAtNode(element);
  };
};

export default componentMount;