import React, {Suspense} from "react";
import { Provider } from "react-redux";

import apiConfig, { EU_API, US_API } from "@pcloud/web-utilities/dist/api/config.api";

import apiMethod from "@pcloud/web-utilities/dist/api/apiMethod";
import apiInit from "@pcloud/web-utilities/dist/api/init";
import { initTracking, GA_FLAG, FBQ_FLAG } from "@pcloud/web-utilities/dist/lib/tracking";
import { EU_LOCATIONID, US_LOCATIONID } from "@pcloud/web-utilities/dist/config/constants";

import { getLocationById } from "@pcloud/web-utilities/dist/api/utils.api";
import { getSearch, getHash, rcookie, setcookie } from "@pcloud/web-utilities/dist/lib/utils";

import componentMount from "../lib/componentMount";

const CookieConsent = React.lazy(() => import(/* webpackChunkName: "CookieConsent" */ "@pcloud/web-utilities/dist/resources/components/CookieConsent"));
const CookieConsentModal = React.lazy(() => import(/* webpackChunkName: "CookieConsentModal" */ "@pcloud/web-utilities/dist/resources/components/CookieConsentModal"));
const LoginOrSignup = React.lazy(() => import(/* webpackChunkName: "LoginOrSignup" */ "@pcloud/web-utilities/dist/authentication/components/UserAuthentication2/LoginOrSignup"));
const SocialProofTooltips = React.lazy(() => import(/* webpackChunkName: "SocialProofTooltips" */ "@pcloud/web-utilities/dist/resources/components/SocialProofTooltips"));
const UnverifiedUsersAlert = React.lazy(() => import(/* webpackChunkName: "UnverifiedUsersAlert" */ "@pcloud/web-utilities/dist/resources/components/UnverifiedUsersAlert"));
const PromoModal = React.lazy(() => import(/* webpackChunkName: "PromoModal" */ "@pcloud/web-utilities/dist/resources/components/PromoModal"));
const RegistrationForm = React.lazy(() => import(/* webpackChunkName: "RegistrationForm" */ "@pcloud/web-utilities/dist/authentication/components/UserAuthentication2/InvitationRegistration/RegistrationForm"));
const VivacomRegistration = React.lazy(() => import(/* webpackChunkName: "VivacomRegistration" */ "@pcloud/web-utilities/dist/resources/components/VivacomRegistration"));
const Timer = React.lazy(() => import(/* webpackChunkName: "Timer" */ "@pcloud/web-utilities/dist/resources/components/Timer"));
const SupportRatingAgent = React.lazy(() => import(/* webpackChunkName: "SupportRatingAgent" */ "@pcloud/web-utilities/dist/resources/components/SupportRatingAgent"));
const Footer = React.lazy(() => import(/* webpackChunkName: "Footer" */ "@pcloud/web-utilities/dist/layout/Footer"));
const Header = React.lazy(() => import(/* webpackChunkName: "Header" */ "@pcloud/web-utilities/dist/layout/Header"));

import Popup from "@pcloud/web-utilities/dist/lib/Popup";
import authModal from "@pcloud/web-utilities/dist/lib/authModal";

import store from "@pcloud/web-utilities/dist/lib/state/store";

import {userSlice} from "@pcloud/web-utilities/dist/lib/state/reducers/pcloud/userSlice";
import {notificationSlice} from "@pcloud/web-utilities/dist/lib/state/reducers/pcloud/notificationSlice";

function bindActionCreators(dispatch) {
  let actionCreators = {
    pCloudUser: userSlice.actions,
    pCloudNotifications: notificationSlice.actions
  }
  let bindActions = {};
  for (let reducer in actionCreators) {
    if (!(reducer in bindActions)) {
      bindActions[reducer] = {};
    }

    for (let action in actionCreators[reducer]) {
      bindActions[reducer][action] = (...args) =>
          dispatch(actionCreators[reducer][action](...args));
    }
  }

  return bindActions;
}

const state = {
  dispatch: (...args) => store.dispatch(...args),
  getState: (...args) => store.getState(...args),
  subscribe: (...args) => store.subscribe(...args),
  actions: bindActionCreators(store.dispatch)
};

const components = {
  LoginOrSignup: componentMount(props => <Provider store={store}><Suspense fallback={''}><LoginOrSignup {...props} /></Suspense></Provider>),
  SocialProofTooltips: componentMount(props => <Suspense fallback={''}><SocialProofTooltips {...props} /></Suspense>),
  CookieConsent: componentMount(props => <Suspense fallback={''}><CookieConsent {...props} /></Suspense>),
  CookieConsentModal: componentMount(props => <Suspense fallback={''}><CookieConsentModal {...props} /></Suspense>),
  UnverifiedUsersAlert: componentMount(props => <Provider store={store}><Suspense fallback={''}><UnverifiedUsersAlert {...props} /></Suspense></Provider>),
  PromoModal: componentMount(props => <Suspense fallback={''}><PromoModal {...props} /></Suspense>),
  RegistrationForm: componentMount(props => <Provider store={store}><Suspense fallback={''}><RegistrationForm {...props} /></Suspense></Provider>),
  VivacomRegistration: componentMount(props => <Provider store={store}><Suspense fallback={''}><VivacomRegistration {...props} /></Suspense></Provider>),
  Timer: componentMount(props => <Suspense fallback={''}><Timer {...props} /></Suspense>),
  SupportRatingAgent: componentMount(props => <Suspense fallback={''}><SupportRatingAgent {...props} /></Suspense>),
  Footer: componentMount(props => <Suspense fallback={''}><Footer {...props} /></Suspense>),
  Header: componentMount(props => <Provider store={store}><Suspense fallback={''}><Header {...props} /></Suspense></Provider>),
};

self.pCloudGlobals = {
  apiInit,
  apiConfig,
  apiMethod,
  Popup,
  authModal,
  components,
  getSearch,
  getHash,
  rcookie,
  setcookie,
  state,
  initTracking,
  GA_FLAG,
  FBQ_FLAG,
  EU_LOCATIONID,
  US_LOCATIONID,
  EU_API,
  US_API,
  getLocationById,
};
